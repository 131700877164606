import React from "react";
import "./index.css"
import Block,{ PendingMessageBlock } from "../../pageComponents/block"
import { MessageBlockchain } from "mantovacoin";
import pendingBlockInstantiator from "../../../lib/pendingBlockInstantiator";
import BlockStatus from "../../../lib/BlockStatus";
import SelectDifficulty from "../../pageComponents/selectDifficulty";
import JSONTreeView from "../../pageComponents/jsonTreeView";
import BasicAnalytics from "../../pageComponents/basicAnalytics";
import { Grid } from "@material-ui/core";


function BlockchainBox(){


     // States
  const [blockchain, setBlockchain] = React.useState({messageBlockchain: new MessageBlockchain([],2,"")})
  const [pendingBlock, setPendingBlock] = React.useState({pendingMessageBlock: pendingBlockInstantiator(blockchain.messageBlockchain.chain[0].hash)})
  const [showPendingBlockSpinner, setShowPendingBlockSpinner] = React.useState(false)
  const [showBlockSpinner, setShowBlockSpinner] = React.useState(false)
  const [miningTime, setMiningTime] = React.useState(0)
  
  
  const updateDifficulty = (value) => {
    const {messageBlockchain} = blockchain
    messageBlockchain.difficulty = value
    setBlockchain({messageBlockchain: messageBlockchain})
  }

  const minePendingMessage = async () => {
    // Mine the pending message
    const {messageBlockchain} = blockchain
    const {pendingMessageBlock} = pendingBlock
    messageBlockchain.pendingMessage = pendingMessageBlock.message

    const chainSize = messageBlockchain.chain.length


    setShowPendingBlockSpinner(true)
    
    setTimeout(async ()=>{
      const startingMiningTime = Date.now()  
      await messageBlockchain.minePendingMessage()
      const endingMiningTime = Date.now()  

      setShowPendingBlockSpinner(false)
      const newBlockHash = messageBlockchain.chain[chainSize].hash

      // update the state
      setBlockchain({messageBlockchain: messageBlockchain})
      setPendingBlock({pendingMessageBlock:pendingBlockInstantiator(newBlockHash)})
      setMiningTime((endingMiningTime - startingMiningTime)/1000)
    }, 100)        
  }

  const handleOnChange = (blockPosition, message) => {

    const {messageBlockchain} = blockchain 
    
    messageBlockchain.chain[blockPosition].message = message
    setBlockchain({messageBlockchain: messageBlockchain})
  }

  const assignBlockStatus = (blockPosition) => {
    const {messageBlockchain} = blockchain
    const invalidBlockPosition = messageBlockchain.detectWhichBlockIsInvalid()

    if(invalidBlockPosition === -1)
      return BlockStatus.VALID

    if (blockPosition >= invalidBlockPosition)
      return BlockStatus.INVALID

    return BlockStatus.VALID
  }

  const updateThePendingMessage = (message) => {
    const {pendingMessageBlock} = pendingBlock
    pendingMessageBlock.message = message
    setPendingBlock({pendingMessageBlock: pendingMessageBlock})
  }

  const mineSpecificBlock = async (blockPosition) => {
    const {messageBlockchain} = blockchain
    
    setShowBlockSpinner(true)
     
    setTimeout(async ()=>{
        const startingMiningTime = Date.now() 
        await messageBlockchain.mineSpecificBlock(blockPosition)
        const endingMiningTime = Date.now()  
        setBlockchain({messageBlockchain: messageBlockchain})
        setShowBlockSpinner(false)
        setMiningTime((endingMiningTime - startingMiningTime)/1000)
    }, 100) 
  }

  const determineIfMineButtonShouldShow = (blockPosition) => {
    return assignBlockStatus(blockPosition) === BlockStatus.INVALID && blockchain
      .messageBlockchain.detectWhichBlockIsInvalid() === blockPosition
  }

  const determineIfBlockShouldShowSpinner = (blockPosition) => {
    const {messageBlockchain} = blockchain
    const invalidBlockPos = messageBlockchain.detectWhichBlockIsInvalid()

    if(invalidBlockPos === blockPosition)
      if(showBlockSpinner)
        return true
    return false 
  }



  const {messageBlockchain} = blockchain
  const {pendingMessageBlock} = pendingBlock

    return <Grid
      container
      direction="row"
    >
      <Grid
      container
      direction="row"
      xs={12}
      sm={12}
      md={8}
      lg={8}
    >
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="topBlockChainPanel"
      >
        <SelectDifficulty 
                    updateDifficulty={updateDifficulty}
                    difficulty={messageBlockchain.difficulty}/>
        <BasicAnalytics blockchain={messageBlockchain}
          timeOfLastBlockMining={miningTime}/>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="middleBlockChainPanel"
        direction="row"
        justifyContent="left"
      >
        {
          /**Blockchain: Excluding Pending Block */
          messageBlockchain.chain
            .map((block, i)=>(
              <Block key={i}
                blockPosition={i}
                block={block}
                mineSpecificBlock={mineSpecificBlock}
                handleOnChange={handleOnChange}
                blockStatus={assignBlockStatus(i)}
                mineButtonShouldShow={determineIfMineButtonShouldShow(i)}
                showSpinner={determineIfBlockShouldShowSpinner(i)}
              />))
        }
        {
          /**Pending Block (appended to the blockchain) */
          <PendingMessageBlock block={pendingMessageBlock}
            blockPosition={messageBlockchain.chain.length}
            minePendingMessage={minePendingMessage}
            updateThePendingMessage={updateThePendingMessage}
            blockStatus={BlockStatus.PENDING}
            mineButtonShouldShow={messageBlockchain.isValid()}
            showSpinner={showPendingBlockSpinner}
          />
        }
      </Grid>
    </Grid>
    <Grid
        container
        xs={12}
        sm={12}
        md={4}
        lg={4}
      >
        <JSONTreeView
          blockchain={messageBlockchain}
        />
      </Grid>
  </Grid>
}

export default BlockchainBox;