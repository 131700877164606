import React from "react"
import Fetcher, { Result } from "../fetcher"

export default function FetchCurrentCirculationPlay(){

    const [totalBtc, setTotalBtc] = React.useState(0)
    const [currentReward, setCurrentReward] = React.useState(0)
    const [fetchError, setFetchError] = React.useState("")
    
    const fetchCurrentSupplyAndReward = async (setShowResults, setShowSpinner, setShowError) => {
        setShowSpinner(true)

        try{
            const totalSatoshis = await fetch("https://blockchain.info/q/totalbc").then(res=>res.text())
            const currentReward = await fetch("https://blockchain.info/q/bcperblock").then(res=>res.text())

            setTotalBtc(parseFloat(totalSatoshis / 1000000))
            setCurrentReward(currentReward)

            setShowSpinner(false)
            setShowResults(true)
        }catch(e){
            console.log(e.message)
            setShowSpinner(false)
            setFetchError(e.message)
            setShowError(true)
        }
        
    }
    
    return <Fetcher 
        fecthingFunction={fetchCurrentSupplyAndReward} 
        fetchingTitle={"FETCH CURRENT REWARD AND BTC IN CIRCULATION"}
        errorMessage={fetchError}
        results={[
            new Result("TOTAL BTC IN CIRCULATION", totalBtc.toFixed(8).concat(" bitcoins")),
            new Result("PERCENTAGE OF MINED BITCOINS IN RELATION TO THE MAX SUPPLY", parseFloat(totalBtc / 21000000).toFixed(2).toString().concat("%")),
            new Result("CURRENT REWARD", currentReward.toString().concat(currentReward < 2? " bitcoin": " bitcoins")),
        ]}
    />
}