import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import BlockStatus from "../../../lib/BlockStatus";
import shortenSHA256 from "../../../lib/shortenSHA256";
import GreenTick from "../greenTick";
import RedX from "../redX";
import Spinner from "../spinner";
import "./index.css"

function Block({
    block:{timestamp, previousBlockHash, hash, nonce, message:message_},
     mineSpecificBlock, handleOnChange, blockPosition, blockStatus, mineButtonShouldShow, showSpinner}){
    
    const [message, setMessage] = React.useState(message_)


    const updateMessageState = e => {
        const updatedMessage = e.target.value
        setMessage(updatedMessage)
        handleOnChange(blockPosition, updatedMessage)
    }


    return <Grid
                container
                justifyContent="center"
                alignContent="center"
                xs={12}
                sm={6}
                md={6}
                lg={3}
            > 

                <div className={"block ".concat(blockStatus.toLowerCase())}>
                    <div className="blocknumber">#{blockPosition+1}</div>
                    <div className="space">Previous Block Hash: {shortenSHA256(previousBlockHash === "" ? "None": previousBlockHash)}</div>
                    <hr className="space"/>
                    <div className="space">Date: {new Date(timestamp).toDateString()}</div>
                    <div className="space">Time: {new Date(timestamp).toLocaleTimeString()}</div>
                    <div className="messagetextarea">
                        <textarea placeholder="Insert Message Here" className="messageinput" value={message} rows={10} onChange={updateMessageState} />
                    </div>
                    <div className="space">Nonce: {nonce}</div>
                    <hr className="space"/>
                    <div className="space">Hash: {shortenSHA256(hash)}</div>
                    <hr className="space"/>
                
                    {mineButtonShouldShow?<div className="space">
                        <div className="mineButton"
                            onClick={()=>mineSpecificBlock(blockPosition)}>
                                <div className="buttonflexChild">Mine </div>
                                <div className="buttonflexChild">{showSpinner?<Spinner/>:""}</div>
                            
                        </div>
                    </div>:blockStatus === BlockStatus.VALID?<GreenTick/>:<RedX/>}
                    
                </div>
            </Grid>
}


export function PendingMessageBlock({
    block:{timestamp, previousBlockHash, hash, nonce, message:message_},
     minePendingMessage, updateThePendingMessage, mineButtonShouldShow, showSpinner}){

    const updateMessageState = e => {
        const updatedMessage = e.target.value
        updateThePendingMessage(updatedMessage)
    }

    const performMining = () => {
        minePendingMessage()
    }


    return <Grid
                container
                justifyContent="center"
                alignContent="center"
                xs={12}
                sm={6}
                md={6}
                lg={3}
            > 
    
                <div className={"block pending"}>
                    <div className="blocknumber">#Pending Block</div>
                    <div className="space">Previous Block Hash: {previousBlockHash === "" ? "None": shortenSHA256(previousBlockHash)}</div>
                    <hr className="space"/>
                    <div className="space">Date: {new Date(timestamp).toDateString()}</div>
                    <div className="space">Time: {new Date(timestamp).toLocaleTimeString()}</div>
                    <div className="messagetextarea">
                        <textarea placeholder="Insert Message Here" className="messageinput" value={message_} rows={10} onChange={updateMessageState} />
                    </div>
                    <div className="space">Nonce: {nonce}</div>
                    <hr className="space"/>
                    <div className="space">Hash: {hash}</div>
                    <hr className="space"/>

                    {mineButtonShouldShow?<div className="space">
                        <div className="mineButton"
                            onClick={()=>performMining()}>
                                <div className="buttonflexChild">Mine </div>
                                <div className="buttonflexChild">{showSpinner?<Spinner/>:""}</div>
                            
                        </div>
                    </div>:<Alert severity="info">Mine the invalid blocks before mining the pending block.</Alert>}  
                </div>
            </Grid>

}


export default Block