import React from "react"
import FetchBitcoinDifficulty from "../../pageComponents/fetchBitcoinDifficulty"
import HashPlay from "../../pageComponents/hashPlay"
import FetchLatestBlockPlay from "../../pageComponents/fetchLatestBlockNoncePlay"
import NoncePlay from "../../pageComponents/noncePlay"
import "./index.css"
import FetchCurrentCirculationPlay from "../../pageComponents/fetchCurrentCirculationPlay"
import { SHA256 } from "crypto-js"
import ScrollBackToTop from "../../pageComponents/scrollBackToTop"
import HashExamplesTable from "../../pageComponents/HashExamplesTable"
import { Link } from "react-router-dom"
import { CTAButton } from "../../pageComponents/button"


export default function HowDoesItWork(){


    return <div className="article">
            <h2>About the simulator</h2>
            <p>The purpose of this simulator is to provide you with an introduction to the process of blockchain mining using the Proof of Work (POW) method.</p>

            <p>Mining involves a significant hardware cost, which may lead to reduced responsiveness or freezing of your browser while using this application, as it utilizes your computer's browser to simulate the mining process. To prevent freezing, you can opt for a lower <b>difficulty</b> level, typically ranging from 0 to 5. However, if you do encounter freezing, please be patient as it will eventually stabilize.</p>

            <p>In this simulator, your main actions will involve entering text into the block message input, clicking the "Mine" button, and waiting for the mining process to finish.</p>

<           p>Upon entering the simulator, you will observe three panels within the app: one at the top, another in the middle, and the third on the right-hand side.</p>


        <h3>The top panel</h3>

            <p>
            This is the place where you can fine-tune the difficulty level and also access fundamental real-time analytics regarding the blockchain. These analytics include metrics like the count of valid and invalid blocks, the total number of mined blocks, and the time taken for the mining process:
            </p>
            
            <div className="centerImageContainer max800WidthImage">
                <img className="centerImage" src="/panels/toppanel.png" alt="top-panel"/>
            </div>

            <h3>The middle panel</h3>
            
            <p>
            This is where the visual depiction of the blockchain will be situated. The blockchain's initial state consists of a green Genesis Block and a white pending block that is awaiting the mining process:

            </p>

            <div className="centerImageContainer max800WidthImage">
                <img className="centerImage" src="/panels/middlepanel.png" alt="middle-panel"/>
            </div>

            <p>
            The green color signifies that the block is valid, meaning it has been successfully mined and remains untampered. Tampering with blocks will result in the background color of the block turning red. To tamper with a block, simply modify the text within a <strong>valid/mined</strong> block.
            </p>

            <div className="centerImageContainer max800WidthImage">
                <img className="centerImage" src="/panels/genesistampered.png" alt="genesis tampered"/>
            </div>

            <ScrollBackToTop />
                

            <p>When a block turns red, it indicates that it must be re-mined to regain its validity. However, there are several important considerations to keep in mind when it comes to revalidating blocks:</p>

            <ol>
                <li>Tampering with a block will render all subsequent blocks invalid.</li>
                <li>To restore a block's validity, mining is necessary, and mining requires time.</li>
                <li>The validity of the current block relies on the previous block also being valid. Therefore, validation must occur sequentially, one block at a time.</li>
                <li>As mining is a time-intensive process and only one block can be mined at a time, attempting to tamper with the blockchain becomes a costly and impractical endeavor as the chain's block count increases.</li>
            </ol>

            <p>Below is an illustration of seven valid blocks, each denoted by the green color:</p>

            <div className="centerImageContainer max800WidthImage">
                <img className="centerImage" src="/panels/7validblocks.png" alt="7 valid blocks"/>
            </div>

            <p>However, here is an example that demonstrates how the behavior of the blockchain changes when the second block out of the seven is tampered with. It's important to note that even a slight alteration to the content of the second block will result in all subsequent blocks, including the current one, becoming invalid.</p>
            
            <div className="centerImageContainer max800WidthImage">
                <img className="centerImage" src="/panels/secondtampered.png" alt="Second Block Tampered with"/>
            </div>
            

            <h3>The right panel</h3>

            <p>If you're up for a technical exploration, this panel allows you to navigate through the blockchain data presented in its JSON state representation.</p>

            <p>Please be aware that if you're accessing this simulator on your phone or tablet, the panel will be displayed below the middle panel, rather than on the right side.</p>
        
            <div className="centerImageContainer max400WidthImage">
                <img className="centerImage" src="/panels/JSONtreeview.png" alt="JSON tree view of blockchain"/>
            </div>
            
        <p>
        You're now up to speed and ready to use the simulator. However, if you're new to the concept of blockchain and eager to delve deeper, feel free to continue reading the article.
        </p>
        <h2>Blockchain Mining fundamentals</h2>

        <h3>Hashing</h3>
        <p>
            Each block contains a hash, which is a textual representation of an output derived from a specific input. A hash can be generated from various types of input, including text, images, audio files, and more. The process requires a hashing function to perform the computation.
        </p>
        <div className="diagramArea max400WidthImage"><img className="diagram"src="/diagrams/sha256processdiagram.png" alt={"Hash sha256 input and output process"}/></div>
        <p>
        Here are a few examples illustrating the input and output for the SHA256 hashing algorithm:
        </p>

        <HashExamplesTable />

        <p>
        Feel free to experiment with the text field provided below. Enter any text you like and observe as the hash output is computed and will be displayed.
        </p>

        <HashPlay />
        <h4>Things to consider about hashes</h4>
        <ol>
            <li>The hashing function will consistently produce the same output for a given input. For instance, when provided the lowercase input "hello," the hash "2cf24dba5fb0a30e26e83b2ac5b9e29e1b161e5c1fa7425e73043362938b9824" will always be calculated.</li>
            <li>Consequently, hashes are deterministic rather than random. Despite their appearance, they are entirely predictable.</li>
            <li>A hash is not easily foreseeable, particularly when it comes to guessing the input that generated the hash. Therefore, even a minor modification to the input will result in a completely different hash. For instance, consider the words "supposed" and "suppose," which possess distinct hashes despite differing only by the letter "d".</li>
            <li>A hash is not an encryption of the plain text or input. Hashes are one-way transformations of data, making it impossible to revert them to the original input, unlike encryption.</li>
            <li>The only way to potentially deduce the original input from a hash is by guessing and testing various entries until matching hashes are found.</li>
            <li>Occasionally, two different inputs may produce the same hash output, resulting in a hash collision. The likelihood of a hash collision is exceedingly low. To put this in perspective, the total number of possible SHA256 hashes is 2^256, an immense number. If we consider a collection of 1 billion unique inputs (10^9), the probability of finding two distinct inputs within the collection that produce the same hash is about 4.3 x 10^-60, practically impossible (winning the lottery is more likely than encountering a hash collision in a robust hashing algorithm).</li>
        </ol>

        <h4>Mining Process using Proof of Work concept</h4>

        <p>A blockchain consists of diverse participants, often referred to as nodes, engaging in a competition to record data within blocks and subsequently attach them to the chain. While anyone has the ability to contribute to it, a block will only be added to the chain after a certain task is completed and substantiated to the network as being correctly executed.</p>

        <p>
        The task involves setting their computers to tackle the challenge of solving a hash problem. Essentially, the network's miners must identify the "right number" that generates a specific hash. This "right number" is referred to as the Nonce, which can be thought of as a random value that can be fine-tuned to meet the criteria for proof of work.
        </p>

        <div className="diagramArea max400WidthImage">
            <img className="diagram" src="/diagrams/MiningProblemSolving.png" alt={"Mining Problem Solving Process"}/>
        </div>

        <p>The challenge involves utilizing the difficulty level and the hash of the pending block to create an altered hash. This modified hash is the outcome of appending a specific number of zeros to the beginning of the hash. The quantity of zeros added is determined by the difficulty level. In the context of this simulator, a difficulty level of 1 corresponds to a single leading zero.</p>

        <p>The mining process then revolves around adjusting the value of the nonce so that the initial section of the block hash aligns with the zeros at the start of the modified hash.</p>

        <p>To illustrate the process of guessing the nonce, let's examine the exercise below. Imagine the difficulty level is set to 2. In this scenario, you are aiming to find a hash that begins with 2 zeros. For the purpose of this exercise, let's represent the entire block data as a "data" message.</p>


        <NoncePlay />

        <p>
            All participants within the network engage in simultaneous mining efforts. The individual who successfully identifies the accurate nonce value first will be the one to add the block to the chain. The selection of the victor is influenced by a combination of chance (owing to the unpredictable nature of hashes) and the configuration of their hardware. Specifically, a higher hash rate of computation per second from your hardware increases the likelihood of winning the competition.
        </p>

        <p>
            Once the winning miner identifies the accurate nonce value, they can transmit it across the network for all nodes/participants to validate its correctness. After successful validation, the network participants collectively acknowledge and recognize the node that provided the nonce value as the legitimate winner. Consequently, they proceed to synchronize their machines with this node's version of the blockchain.
        </p>

        <h4>Is all of this effort excessive? What is the underlying purpose?</h4>

        <p>
            The objective is to uphold the integrity of the blocks, making tampering unfeasible. The data added to the blockchain must remain unchangeable to prevent tampering or manipulation. The decentralized nodes within the network can readily identify and discard blocks transmitted with incorrect nonce values. It all revolves around achieving consensus.
        </p>


        <h4>Doesn't the ability to tamper with mined blocks in this simulator compromise the security of the blockchain?</h4>

        <p>Since you are the sole node in this simulator, you have the capability to alter block values and revalidate them with ease. However, the fundamental concept of a blockchain centers around the existence of decentralized nodes, where you are in constant competition with the broader network to discover the correct nonce value.</p>

        <p>If your intention is to manipulate a block, you would be required to re-mine not only that particular block but also all subsequent blocks. Meanwhile, the remainder of the network is dedicating its efforts to mining solely the latest block. Moreover, you would need to complete the mining of the invalidated blocks before any other node in the network successfully mines the latest block.</p>

        <p>For instance, let's consider that mining a single block takes approximately 10 minutes. Should you choose to tamper with the fifth-to-last block, you would consequently introduce five invalid blocks into the chain, while the rest of the network maintains an intact blockchain. Subsequently, you would need to invest 50 minutes to mine these five blocks. To succeed, you would be required to complete this task in less than 10 minutes, which is the time frame the broader network would take to mine the latest block. The crucial aspect is finishing mining the five blocks before the network completes the latest one.</p>

        <p>However, even if you were to achieve this feat, which is unlikely, your block still faces the risk of being rejected by the network. This is because nodes within the network will align with the majority stream. As you are the sole source transmitting a "different" stream, your version of the blockchain would be rejected. In the end, this process would lead to a waste of time, electricity, hardware wear, and a substantial amount of money, rendering the endeavor unviable.</p>

        <p>Following the rules proves to be significantly more cost-effective compared to acting against them. Hence, adhering to <a href="https://en.wikipedia.org/wiki/Game_theory" target="_blank" rel="noopenet noreferrer">Game Theory</a> principles, you would be "compelled" to avoid breaking the rules.</p>

        
        <h3>Bitcoin Blockchain</h3>
        <p>
        In this segment, we will delve into various features and intriguing aspects of the Bitcoin blockchain.
        </p>
        <div className="danger disclaimer">
            <h4>Disclaimer</h4>
            This section aims to provide an impartial exploration of the Bitcoin blockchain. The article's intention is neither to pass judgment on the merits of Bitcoin nor to advocate for or against its purchase.
        </div>
        
        <p>
            The Bitcoin blockchain stands as a prime example of a decentralized blockchain. Participant identities may remain unknown, yet engagement is possible due to consensus built on verifiability rather than reliance on trust.
        </p>

        <h4>Bitcoin Difficulty Level</h4>

        <p>The Bitcoin algorithm dynamically adjusts its difficulty level to ensure that the average time required to mine each block remains around 10 minutes.</p>

        <p>If there is a sudden increase in computational power across the network, indicating more miners joining to contribute their computing resources to mine blocks, the network would mine blocks more rapidly. In response, the difficulty level would be raised to maintain the target 10-minute block mining time.</p>

        <p>Conversely, if computational power diminishes in the network due to miners powering down their machines, the remaining participants would take longer than 10 minutes to mine a block. The algorithm then reduces the difficulty level to sustain the desired 10-minute mining interval.</p>

        <p>This adjustment of the difficulty level doesn't occur instantaneously; it takes approximately 2016 blocks (equivalent to about two weeks) to come into effect. The current hash rate per second is taken into consideration during the difficulty level adjustment.</p>

        <p>Feel free to press the button below to retrieve the current Bitcoin difficulty level and hash rate. Keep in mind that difficulty updates take place every two weeks.</p>

        <FetchBitcoinDifficulty />

        <h4>Bitcoin latest block nonce</h4>

        <p>Utilize the tool provided below to retrieve the most recent block hash and nonce. Please note that these values are updated every 10 minutes, reflecting the average time required for block mining.</p>

        <FetchLatestBlockPlay />

        <h4>What motivates miners to continue investing in infrastructure and electricity for Bitcoin block mining?</h4>

        <p>In the realm of cryptocurrency, the motivation lies in the acquisition of bitcoin units through block mining, accompanied by transaction fees. The Bitcoin protocol is structured to bestow the victorious miner with new coins, thereby injecting additional bitcoins into the cryptocurrency economy.</p>
       
        <h4>Doesn't the system of rewarding bitcoins contribute to bitcoin inflation?</h4>

        <p>Indeed, with each block successfully mined, additional coins come into existence, consequently augmenting the total amount of circulating bitcoin. Nevertheless, the protocol was strategically devised to cap the total supply at 21 million coins. Consequently, when this cap is reached, miners will find their incentive in collecting fees for incorporating transactions within blocks.</p>

        <h4>How much bitcoin is a miner rewarded each block?</h4>
        <p>To address this question, you must first comprehend the concept of the halving event.</p>

        <p>When the bitcoin protocol was introduced in 2009, the reward for mining each block was set at 50 bitcoins. Approximately every 210,000 blocks (which translates to about 4 years), this reward is halved, leading to what we term the halving event.</p>

        <ol>
            <li>In 2012, the initial halving of bitcoin took place, reducing miners' earnings from 50 bitcoins to 25 bitcoins per block.</li>
            <li>In 2016, the second halving occurred, further reducing the reward to 12.5 bitcoins.</li>
            <li>In 2020, the third halving transpired, causing the reward to decrease to 6.25 bitcoins.</li>
        </ol>

        <p>The total number of bitcoins in circulation is projected to reach 21 million by the year 2140. However, at the time of composing this article, over 90% of the maximum supply has already been mined.</p>

        <p>Utilize the tool below to retrieve the current count of bitcoins in circulation and the prevailing reward for each mining block. Please note that while a block takes 10 minutes to mine, the API employed to obtain these values experiences a delay of up to one hour for updating purposes.</p>

        <FetchCurrentCirculationPlay />

        <h3>Conclusion</h3>

        <p>In conclusion, delving into the intricacies of the Bitcoin blockchain reveals a decentralized ecosystem that operates on the principles of verifiable consensus. The blockchain's design, exemplified by the Bitcoin protocol, showcases a unique approach to maintaining security and integrity without relying on centralized authority.</p>

        <p>Through processes like mining, where participants compete to solve intricate mathematical puzzles, the blockchain ensures that transactions are validated and added to the immutable ledger. This ensures that the tampering of data is nearly impossible and guarantees the reliability of the network.</p>

        <p>As we've explored, the reward system for miners, coupled with the halving events, offers a carefully calibrated mechanism to control the supply and distribution of bitcoins. Looking ahead, the balance between miners' incentives and the protocol's limitations will continue to shape the evolution of the Bitcoin blockchain, ultimately determining its trajectory within the broader landscape of digital currencies and decentralized technologies.</p>

        <h3>Time to play with the simulator</h3>
        <p>Click the button below to access the simulation tool.</p>
        
        <Link to="/simulator"><CTAButton>Go to Simulator</CTAButton></Link>


        
    </div>
    
}