import { createTheme, Grid, MenuItem, TextField, ThemeProvider } from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import React from "react"
import "./index.css"

export default function SelectDifficulty({updateDifficulty, difficulty}){

    const warningComponent = (<div className="warning diffcultyWarning">
        Be mindiful that difficulty level greater than or equal 6 may take several minutes to complete
        and your browser may go unresponsive during the process.
    </div>)

    const updateSelection = (e) => {
        updateDifficulty(parseInt(e.target.value))
    }


    return <Grid
        container
        justifyContent="center"
        alignContent="center"
        xs={12}
        sm={6}
        md={6}
        lg={6}
    >
        <ThemeProvider theme={createTheme({
            palette:{
                primary: {
                    main:green[900]
                }
            }
        })}>
        <TextField
            value={difficulty}
            onChange={updateSelection}
            select
            label="Difficulty Level"
            className="difficultyTextField"
            variant="outlined"
            color="primary"
        >
            {Array.from(Array(10).keys())
                .map(key=>key+1)
                .map((option, i)=><MenuItem key={i} value={option}>{option}</MenuItem>)
            }
        </TextField>
        </ThemeProvider>
        {difficulty>=6?warningComponent:""}
    </Grid>
}
