import React from "react"
import "./index.css"
import SHA256 from "crypto-js/sha256"
import shortenSHA256 from "../../../lib/shortenSHA256"
import {GreenButton} from "../button"
import { Button, createTheme, Grid, Slider, TextField, ThemeProvider, Typography } from "@material-ui/core"
import { green } from "@material-ui/core/colors"



export default function NoncePlay(){

    const [nonce, setNonce] = React.useState(0)
    const [nonceInput, setNonceInput] = React.useState(0)
    const [showResponse, setShowResponse] = React.useState(false)
    const [showCorrectAnswer, setShowCorrectAnswer] = React.useState(false)

    const dataAppendsNonce = "data".concat(nonce)

    const hash = shortenSHA256(SHA256(dataAppendsNonce).toString())

    const updateForm = e => {
        setNonceInput(parseInt(e.target.value))
        setShowResponse(false)
    }

    return <Grid
            container spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="nonceContainer"
            >
                <Typography 
                    variant="h4"
                    align="center"
                >
                    Can you guess what's the correct nonce value that would initiate the hash with two leading zeros?
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                >
                    Utilize the slider provided below to fine-tune the nonce value which, when combined with the "data" message, generates a hash with the requirement that the first two characters of the hash must be "00". Your objective is to determine the accurate nonce value. If desired, you can click <strong className="hereReveal" onClick={e=>{setShowCorrectAnswer(true)}}>here</strong> to unveil the solution.
                </Typography>
            {showCorrectAnswer?
            <Grid 
                container spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="info"
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Typography
                        gutterBottom
                    >
                        The correct answer is 137.
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Button 
                        onClick={()=>{setShowCorrectAnswer(false)}}
                        variant="contained"
                        color="secondary"
                    >
                        Dismiss
                    </Button>
                </Grid>
            </Grid>:""}
                <Grid
                    container spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={2}
                    className="interactivearea"
                >
                    <Typography gutterBottom>
                        Nonce value: {nonce}
                    </Typography>
                </Grid>
                
                <Grid
                    container 
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                >
                    <div 
                        className="nonceInputSlider"
                    >
                        <ThemeProvider theme={createTheme({palette:{
                            primary: green
                        }})}>
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=>value}
                            min={0}
                            max={150}
                            step={1}
                            value={nonce}
                            color="primary"
                            valueLabelDisplay="auto"
                            onChange={(e, newValue)=>setNonce(parseInt(newValue))}
                        />
                        </ThemeProvider>
                    </div>
                </Grid>

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                >
                    <Typography gutterBottom>Nonce appended to "data": {dataAppendsNonce}</Typography>
                </Grid>
                <Grid
                    container spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={1}
                >
                    =
                </Grid>
                
                <Grid
                    container spacing={0}                    
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                >
                    <Typography gutterBottom>
                        Hash: {hash.substring(0,2) === "00"? <>
                     <strong className="highlightTwoZeros">{hash.substring(0,2)}
                 </strong>{hash.substring(2, hash.length-1)}
                 </>:hash}
                    </Typography>
            </Grid>

            <Grid
                container spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="nonceform"
            >
                <Grid
                    container spacing={0}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    justifyContent="center"
                    alignItems="center"
                >
                    <TextField
                        id="outlined-number"
                        label="Insert nonce value"
                        type="number"
                            InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={updateForm}
                    />
                </Grid>
                
                <Grid
                    container spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <GreenButton
                        onClick={()=>{setShowResponse(true)}}
                        variant="contained"
                        
                    >Submit</GreenButton>
                </Grid>
            </Grid>


            {showResponse && nonceInput ? <Grid container>
                    {SHA256("data"+nonceInput)
                    .toString()
                    .startsWith("00")? 
                    <Grid 
                        className="success"
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        <Typography>
                            That's right! Keep up the good work! :)
                        </Typography>
                    </Grid>:
                    <Grid 
                        className="danger"
                        style={{border:"1px solid black"}}
                        container 
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        <Typography>
                            Oh, snap! Wrong answer. Try again.
                        </Typography>
                    </Grid>
                }
            </Grid>: ""}
    </Grid>
}