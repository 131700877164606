import React from "react"
import { CTAButton } from "../../pageComponents/button"
import "./index.css"
import {AnimatedOnScroll} from "react-animated-css-onscroll"
import TerminalDiv from "../../pageComponents/terminalDiv"
import { Link } from "react-router-dom"
import CenterizeText from "../../pageComponents/centerizeText"

export default function LandingPage(){

    return <div>
    <div className="simulatorInvitation landing">
      <AnimatedOnScroll animationIn={"pulse"}>
        <CenterizeText>
          <h2>Welcome to the Blockchain Simulator</h2>
        </CenterizeText>
      </AnimatedOnScroll>
      <CenterizeText>
      <h4>An introductory guide to Blockchain Technology.</h4>
      </CenterizeText>
      <Link to="/simulator"><CTAButton>Go to Simulator</CTAButton></Link>     
    </div>
    <div className="docsInvitation landing">
        <AnimatedOnScroll animationIn="bounceInRight">
          <CenterizeText>
            <h2>Learn more about the blockchain technology</h2>
          </CenterizeText> 
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="bounceInLeft">
            <CenterizeText>
              <h4>Comprehend the functionality of this simulator and receive a concise introduction to the proof of work blockchain.</h4>
            </CenterizeText>
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="bounceInUp">
            <Link to="/howDoesItWork"><CTAButton>Go to the Article</CTAButton></Link>
          </AnimatedOnScroll>
    </div>

    <div className="runLocallyLanding landing">
        <AnimatedOnScroll animationIn="bounceInRight">
          <CenterizeText>
            <h2>Want to try it locally?</h2>
          </CenterizeText>
             
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="bounceInLeft">
            <CenterizeText>
              <h4>You can run this simulation using the following commands:</h4>
            </CenterizeText>
          </AnimatedOnScroll>
          <TerminalDiv>docker pull fmmt/marcelus-bcsim:1.0.1</TerminalDiv>
          <TerminalDiv>docker run -p 3000:3000 -d fmmt/marcelus-bcsim:1.0.1</TerminalDiv>
    </div>
</div>
}