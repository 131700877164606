import React from "react"
import Fetcher, { Result } from "../fetcher"

export default function FetchLatestBlockPlay(){

    const [latestBlockNonce, setLatestBlockNonce] = React.useState(0)
    const [latestBlockHash, setLatestBlockHash] = React.useState("")
    const [fetchError, setFetchError] = React.useState("")

    const fetchLatestHashAndNonce = async (setShowResults, setShowSpinner, setShowError) => {
        setShowSpinner(true)

        try{
            const latestHash_ = await fetch("https://blockchain.info/q/latesthash").then(res=>res.text())
            const fullLatestRawBlock = await fetch(`https://blockchain.info/rawblock/${latestHash_}`).then(res=>res.json())
    
            setLatestBlockHash(latestHash_)
            setLatestBlockNonce(fullLatestRawBlock.nonce)
    
            setShowSpinner(false)
            setShowResults(true)
        }catch(e){
            console.log(e.message)
            setShowSpinner(false)
            setFetchError(e.message)
            setShowError(true)
        }
    }



    return <Fetcher 
        fecthingFunction={fetchLatestHashAndNonce} 
        fetchingTitle={"FETCH LATEST BLOCK HASH AND NONCE"}
        errorMessage={fetchError}
        results= {[new Result("LATEST BLOCK HASH", latestBlockHash),
            new Result("LATEST BLOCK NONCE", latestBlockNonce.toLocaleString())]}
        />

}