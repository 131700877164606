const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
      color: "white"
    },
    appBar:{
      backgroundColor: "#172922",
      height: "8vh"
    },
  
    buttonBar: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      margin: "10px",
      paddingLeft: "16px",
      right: 0,
      position: "relative",
      display: "flex",
      gap: "10px"
    },
  
    buttonCollapse: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      },
      margin: "10px",
      boxShadow: "none"
    },
  
    link:{
      textDecoration: "none",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        color: "#000"
      },
    },
  
    linkLogo:{
      textDecoration: "none",
      color: "#fff"
    },
  
    navButton:{
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      backgroundColor: "#1a3a2e",
      '&:hover':{
        backgroundColor: "#76978b",
      }
    },
  
  });

export default styles;