import React from "react"
import "./index.css"
import SHA256 from "crypto-js/sha256"
import { Grid, TextField, Typography } from "@material-ui/core"

export default function HashPlay(){

    const [hash, setHash] = React.useState("")

    return <Grid 
        container spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="hashplaycontainer">

    <Grid 
        // item 
        xs={12} 
        sm={12} 
        lg={6}
        container 
        direction="row"
        justifyContent="center"
        alignItems="center" 
        className="hashplayinput">
        <TextField 
            id="outlined-basic" 
            label="Insert any text here" 
            variant="outlined" 
            onChange={e=>setHash(SHA256(e.target.value).toString())}/>
    </Grid>
    <Grid 
        container 
        direction="row"
        justifyContent="center"
        alignItems="center" 
        xs={12}
        sm={12} 
        lg={6} 
        className="hashplayoutput">
      <Typography 
        variant="caption" 
        display="block" 
        gutterBottom
        className="hashplayoutputtext">{hash}</Typography>
    </Grid>
  </Grid>
}