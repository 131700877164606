import { Grid } from "@material-ui/core"
import React from "react"
import "./index.css"

export default function BasicAnalytics({blockchain, timeOfLastBlockMining}){
    
    const calculateNumberOfInvalidBlocks = (blockchain) => {
        const invalidBlockPosition = blockchain.detectWhichBlockIsInvalid()
        if(invalidBlockPosition === -1)
            return 0
        else
            return blockchain.chain.length - invalidBlockPosition 
    }

    const calculateNumberOfValidBlocks = (blockchain) => {
        const invalidBlockPosition = blockchain.detectWhichBlockIsInvalid()
        if(invalidBlockPosition === -1)
            return blockchain.chain.length
        else
            return invalidBlockPosition 
    }

    return <Grid
        container
        justifyContent="center"
        alignContent="center"
        xs={12}
        sm={6}
        md={6}
        lg={6}
    ><div className="analyticsContainer">
        <div className="basicBlockchainTitle">Basic blockchain analytics</div>
        <div className="analyticsChild">
            <div className="nameOfItem">Invalid blocks:</div>
            <div className="valueOfItem invalidBlockItem">{calculateNumberOfInvalidBlocks(blockchain)}</div>
        </div>
        <div className="analyticsChild">
            <div className="nameOfItem">Valid blocks:</div>
            <div className="valueOfItem validBlockItem">{calculateNumberOfValidBlocks(blockchain)}</div>
        </div>
        <div className="analyticsChild">
            <div className="nameOfItem">Last block mining time:</div>
            <div className="valueOfItem">{timeOfLastBlockMining} seconds</div>
        </div>
        <div className="analyticsChild">
            <div className="nameOfItem">Total Blocks (including pending block):</div>
            <div className="valueOfItem">{blockchain.chain.length +1}</div>
        </div>
    </div>
    </Grid>
}