import React from "react"
import getNowDateAndTimeString from "../../../lib/getNowDateAndTimeString"
import {GreenButton} from "../button"
import Spinner from "../spinner"
import "./index.css"

export default function Fetcher({fecthingFunction, results = [], fetchingTitle="", errorMessage}){

    const [showResults, setShowResults] = React.useState(false)
    const [showSpinner, setShowSpinner] = React.useState(false)
    const [showError, setShowError] = React.useState(false)

    return <><div className="fetchArea">
        <GreenButton
            onClick={()=>fecthingFunction(setShowResults, setShowSpinner, setShowError)}
        >{fetchingTitle}</GreenButton>
        <div className="outputArea">
            {showSpinner?<Spinner />:""}
            {showResults?
            results.map((result, i)=><div className="fetchedArea" key={"result".concat(i)}>
                <div className="fetchedAreaItem">
                    <div>{result.label}: </div>
                    <div>{result.data}</div>
                </div>
                {i !== results.length -1? <hr /> : ""}
            </div>)
            :""}
            
        </div>
        {showResults?<div className="updateStatus">Last updated: {getNowDateAndTimeString()}</div>:""}
        
    </div>
    {showError?<div className="danger">{errorMessage}</div>:""}
    </>
}

export class Result{
    constructor(label, data){
        this.label = label;
        this.data = data;
    }
}