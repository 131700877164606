import React from "react";
import BlockchainBox from "./components/pages/blockchainBox"
import "./App.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import HowDoesItWork from "./components/pages/howDoesItWork"
import LandingPage from "./components/pages/landingPage"
import SiteAppBar from "./components/pageComponents/siteAppBar";

function App() {

  return (
    <Router>
      <div>
        <SiteAppBar />
        <div className="bodyApp">
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/howDoesItWork" element={<HowDoesItWork />} />
                <Route path="/simulator" element={<BlockchainBox/>} />
            </Routes> 
        </div>
        <footer className="App-footer">Felipe Mantovani © 2023</footer>
      </div>
    </Router>   
  );
}

export default App;
