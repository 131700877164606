import React from "react"
import BackToTop from "react-back-to-top-button"
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from "./styles"
import { withStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

function ScrollBackToTop({classes}){
    return (
        <BackToTop
            showOnScrollUp
            showAt={100}
            speed={1500}
            easing="easeInOutQuint"
        >
            <Button className={classes.scrollContainer}>
                <KeyboardArrowUpIcon />
            </Button>
        </BackToTop>
    )
}

export default withStyles(styles)(ScrollBackToTop)