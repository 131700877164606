import React from "react"
import Fetcher, { Result } from "../fetcher"


export default function FetchBitcoinDifficulty(){

    const [bitcoinDifficulty, setBitcoinDifficulty] = React.useState("")
    const [bitcoinCurrentHashRate, setBitcoinCurrentHashRate] = React.useState("")
    const [fetchError, setFetchError] = React.useState("")


    const fetchBitcoinDifficulty = async (setShowResults, setShowSpinner, setShowError) => {
        setShowSpinner(true)
        try{
            const difficulty = await fetch("https://blockchain.info/q/getdifficulty").then(res=>res.json())
            const currentHashRate = await fetch("https://blockchain.info/q/hashrate").then(res=>res.json())
            setBitcoinDifficulty(difficulty)
            setBitcoinCurrentHashRate(currentHashRate)
            setShowResults(true)
            setShowSpinner(false)
        }catch(e){
            console.log(e.message)
            setShowSpinner(false)
            setFetchError(e.message)
            setShowError(true)
        }
        
    }

    return <Fetcher 
        fecthingFunction={fetchBitcoinDifficulty} 
        fetchingTitle={"FETCH BTC HASH RATE AND DIFFICULTY"}
        errorMessage={fetchError}
        results= {[new Result("CURRENT DIFFICULTY", parseInt(bitcoinDifficulty).toLocaleString()),
            new Result("CURRENT HASHRATE", parseFloat(bitcoinCurrentHashRate / 1000).toFixed(1).toLocaleString().concat(" TH/s"))]}
    />
}