import {ThemeProvider, createTheme } from "@material-ui/core/styles"
import  { Button } from "@material-ui/core"
import React from "react"
import "./index.css"
import { green } from "@material-ui/core/colors"

export default function MyButton({onClickHandler, className, children}){
    return <div className="foo">
        <button 
            onClick={onClickHandler}
            className={className}>{children}
        </button>
    </div>
}

export function RedButton({onClickHandler, children}){
    return <MyButton onClickHandler={onClickHandler} className={"redButton"}>{children}</MyButton>
}

export function CTAButton({onClickHandler, children, className=""}){
    return <MyButton onClickHandler={onClickHandler} className={"cta".concat(" ").concat(className)}>{children}</MyButton>
}


const theme = createTheme({
    palette: {
        primary: green
    }
});

export function GreenButton({onClick, children}){
    return <ThemeProvider theme={theme}>
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
        >
            {children}
        </Button>
    </ThemeProvider>
}
