import React from "react"
import ReactJson from 'react-json-view'
import "./index.css"

export default function JSONTreeView({blockchain}){
    return  <div className="blockchainJSONPanel">
        <div className="jsonTreePanelTitle">Blockchain JSON tree view</div>
        <hr className="jsonTreeDevider"/>
        <ReactJson src={blockchain}
        theme={"threezerotwofour"} 
        name={"blockchain"}/>
    </div>
}