import React from "react"
import "./index.css"
import ClipboardIcon from 'react-clipboard-icon'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function TerminalDiv({children}){

    const [showCopyClipMessage, setShowCopyClipMessage] = React.useState(false)

    const copyToClipBoard = () => {
        setShowCopyClipMessage(true)

        setTimeout(()=>{
            setShowCopyClipMessage(false)
        }, 2500)
    }

    return <>
            <CopyToClipboard text={children}
          onCopy={() => copyToClipBoard}>
        <div
            className="terminal"
            onClick={()=>{copyToClipBoard()}}>
            <div className="clipboardIcon">
                <ClipboardIcon
                    size={20}
                    style={{fill:'red'}}
                />
            </div>
            <div>
                {">> ".concat(children)}
            </div>
            {showCopyClipMessage?<div className="success copiedMessage">
                Copied to clipboard.
            </div>:""}
        </div>
        </CopyToClipboard>
        </>
    

}