import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { SHA256 } from "crypto-js"
import React from "react"

export default function HashExamplesTable(){

    class HashExamplesTable{
        constructor(input){
            this.input = input
            this.output = SHA256(input).toString()
        }
    }

    const rows = [
        new HashExamplesTable("cat"),
        new HashExamplesTable("Cat"),
        new HashExamplesTable("Fantastic"),
        new HashExamplesTable("hello"),
    ]


    return <TableContainer component={Paper}>
        <Table sx={{minWidth:650}} aria-label="hash-example-tables">
            <TableHead>
            <TableRow>
                <TableCell align="center">Input</TableCell>
                <TableCell align="center">Output (Hash)</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, i)=><TableRow
                    key={row.output.concat(i)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {Object.keys(row).map(key=><TableCell component="th" scope="row" align="center">
                            {row[key]}
                        </TableCell>)}
                    </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>
}