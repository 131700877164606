import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Link
} from "react-router-dom"
import styles from './styles';



class ButtonAppBarCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handleMenu = this.handleMenu.bind(this);
  }
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.buttonCollapse}>
        <IconButton onClick={this.handleMenu}>
          <MenuIcon className={classes.menuButton} color="white" aria-label="Menu" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
            <Link className={classes.link} to="/howDoesItWork">
              <MenuItem onClick={this.handleClose}>
              How does it work?
              </MenuItem>
            </Link>
            <Link className={classes.link} to="/simulator">
              <MenuItem onClick={this.handleClose}>
                Simulator
              </MenuItem>
            </Link>
        </Menu>
      </div>
    );
  }
}



function SiteAppBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            <Link className={classes.linkLogo} to="/">
              BlockSimulator
            </Link>
          </Typography>
          <ButtonAppBarCollapse classes={classes} />
          <div className={classes.buttonBar}>
            <Link className={classes.link} to="/howDoesItWork">
              <Button className={classes.navButton} color="inherit">What is this?</Button>
            </Link>
            <Link className={classes.link} to="/simulator">
              <Button className={classes.navButton} color="inherit">Simulator</Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

SiteAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SiteAppBar);

