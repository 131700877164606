const styles = theme => ({
    
    scrollContainer:{
        backgroundColor: "#172922",
        color:"#fff",
        borderRadius: 28,
        '&:hover':{
            backgroundColor: "#76978b"
        }
    }
  
  });

export default styles;